import React from "react";
import { Link } from "gatsby";
import Next from "../components/next";
import Github from "../components/github";
import "../style/index.scss";
import { Helmet } from "react-helmet";

export default () => (
  <>
    <Helmet htmlAttributes={{ lang: "en" }}>
      <title>Mohamed's Porfolio &#129305;</title>
      <meta name="description" content="Full Stack Web Developer Portfolio." />
      <meta name="robots" content="nofollow" />
    </Helmet>
    <div className="landing full fade">
      <div className="boss-section">
        <div className="left">
          <div>
            <Link to="/about">About</Link>
          </div>
          <div>
            <Link to="/projects">Projects</Link>
          </div>
          <div>
            <Link to="/skills">Skills</Link>
          </div>
          <div>
            <Link to="/work">Work</Link>
          </div>
          <div>
            <Link to="/contact">Contact</Link>
          </div>
        </div>
        <div className="right">
          <div className="boss-text">
            <b>I'm</b>
          </div>
          <div className="boss-son">
            <div>
              <b>Mohamed Fall</b>
            </div>
            <div>
              a <b>Full Stack Developer</b>
            </div>
          </div>
        </div>
      </div>
      <Github />
    </div>
    <Next path="/" />
  </>
);
